<template>
  <v-dialog v-model="show" persistent width="500">
    <v-card>
      <v-toolbar dense dark flat color="primary">
        <v-toolbar-title>Confirmar cancelación</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-3">
        <p>
          Para continuar, escribe el número de celular del cliente al que se
          enviará el SMS para confirmar la cancelación de su seguro
        </p>
        <v-form v-model="isFormValid" ref="form" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="numeroSms"
                label="Número de celular"
                type="number"
                maxLength="10"
                :rules="[$rules.min(10), $rules.max(10)]"
                prepend-inner-icon="mdi-cellphone"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <div class="d-md-flex justify-end">
          <v-btn color="secondary" text class="mr-2" @click="$emit('close')">
            <span>Cancelar</span>
          </v-btn>
          <v-btn color="primary" :disabled="!isFormValid" @click="saveNumero()">
            <span>Confirmar cancelación</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },

  data() {
    return {
      isFormValid: false,
      numeroSms: "",
    };
  },

  methods: {
    async saveNumero() {
      await this.$refs.form.validate();
      if (!this.isFormValid) return;
      this.$emit("numeroSmsChanged", this.numeroSms);
    },
  },
};
</script>
